/* global MatgenGlobal, dataLayer, $ */

//import '../../../../sass/styles.scss';
import InitMatgenCore from '@m4c/matgen';

import '../-components/display/generator-skip-link.js';
import { a11yClick } from '../-components/display/a11y-click.js';
import {
  authUI,
  mainContentLoader,
  mainContentLoaderStop,
  showModal,
} from './display';
import {
  getQueryParam,
  buildQueryString,
  configure,
  authCallback,
} from './data/index.js';
import initEvents from './events.js';

if (typeof MatgenGlobal === 'undefined') {
  window.MatgenGlobal = {};
}
if (typeof M4CGlobal === 'undefined') {
  window.M4CGlobal = {};
}

export const initAuth = async () => {
  let user = MatgenGlobal.AuthUser
    ? await MatgenGlobal.AuthUser.getUser()
    : false;
  if (!user) {
    MatgenGlobal.AuthUser = await new MatgenGlobal.AuthUserModule();
  }

  authUI();

  user = MatgenGlobal.AuthUser ? await MatgenGlobal.AuthUser.getUser() : false;

  MatgenGlobal.userInitialized = true;
  MatgenGlobal.emit({ event: 'matgen-event-user-initialized' });
};

export const init = async () => {
  initEvents();
  InitMatgenCore();

  MatgenGlobal.tenant_id = '3ef2b6ec-f02a-4b3c-9864-9d0d02f124fd';
  //await import('../../-components/display/generator-skip-link.js');
  await configure();
  //await import('@m4c/matgen');

  let maintenance = await MatgenGlobal.Amplify.API.get(
    'public',
    `/maintenance?cache_buster=${new Date().getTime()}`
  );

  if (maintenance && Array.isArray(maintenance) && maintenance.length > 0) {
    maintenance = true;
  } else {
    maintenance = false;
  }

  if (maintenance && !window.location.href.includes('maintenance.html')) {
    window.location.href = '/maintenance.html';
  }

  MatgenGlobal.MainContentWrapper = '#main-content';
  MatgenGlobal.MainContentContainer = '#main-whole';

  MatgenGlobal.tenant = 'nia';
  MatgenGlobal.suppressMenus = true;

  MatgenGlobal.a11yClick = a11yClick;
  MatgenGlobal.M4CModal.show = showModal;
  MatgenGlobal.getQueryParam = getQueryParam;
  MatgenGlobal.buildQueryString = buildQueryString;
  MatgenGlobal.AuthCallback = authCallback;

  const flag = MatgenGlobal.getQueryParam('flag');
  if (flag && flag === 'signup_confirm') {
    MatgenGlobal.suppressMainLoaderStop = true;
    mainContentLoader({ showMessage: true });
    $('#loader-message').text('Loading...');
    dataLayer.push({
      event: 'create-account',
      eventCallback: () => {
        window.location.href = '/';
        mainContentLoaderStop();
        delete MatgenGlobal.suppressMainLoaderStop;
      },
    });
  }

  MatgenGlobal.CommonInit();
  if (!MatgenGlobal.globalsInitialized) {
    await initAuth();
  }

  MatgenGlobal.globalsInitialized = true;
  MatgenGlobal.emit({ event: 'matgen-event-globals-initialized' });
};

export default init;
