/* global MatgenGlobal, $, dataLayer, M4CGlobal, Promise */

import { authHeaderOpts, isJsonAnswer } from '../../+auth-global/data/index.js';
import { killModal } from '../../+auth-global/display.js';
import Study from '../../-components/objects/Study.js';
import { reportFormErrors, collectDataUI } from './display.js';
import { v4 as UUID } from 'uuid';
import * as DOMPurify from 'dompurify';

export const loadWebsiteData = async (args, scope) => {
  scope.loader.start(true);
  $('#loader-message').text('Loading website data...');
  const study_id = MatgenGlobal.getQueryParam('study_id');
  MatgenGlobal.currentStudy = await new Study(study_id);
  $('html,body').animate({ scrollTop: 0 }, 50);

  await collectDataUI({
    target: '#website-data',
    scope,
  });
  $('#website-data-wrapper').addClass('loaded');

  scope.loader.stop();
};

class MatgenQuestion {
  constructor(question) {
    this.question = question;
  }

  getType() {
    return this.question.component;
  }

  getPlainTextAnswer() {
    let temp;
    let temp2;
    switch (this.question.component) {
      default:
        if (Array.isArray(this.question.answers)) {
          if (this.question.answers.length === 1) {
            if (this.question.answers[0].userAnswer === '') {
              return 'EMPTY';
            }
            if (this.question.answers[0].userAnswer) {
              return this.question.answers[0].userAnswer;
            }
            return this.question.answers[0];
          }
        }
        return this.question.answers;
      case 'upload-single':
        if (Array.isArray(this.question.userAnswers)) {
          if (this.question.userAnswers.length === 1) {
            if (this.question.userAnswers[0].userAnswer) {
              return this.question.userAnswers[0].userAnswer;
            } else {
              return 'EMPTY';
            }
          }
        }
        return this.question.answers;
      case 'img-select-single':
        temp = this.question.answers.map((a) => {
          if (a.userAnswer === true) {
            return a;
          }
          return 'EMPTY';
        });
        temp2 = temp.filter((t) => t !== 'EMPTY');
        if (temp2.length > 0) {
          return temp;
        }
        return 'EMPTY';
      case 'select-single':
      case 'select-multiple':
        temp = this.question.answers.filter((a) => a.userAnswer === true);
        if (temp.length === 0) {
          return 'EMPTY';
        }
        return this.question.answers.filter((a) => a.userAnswer === true);
      case 'list-select-single':
        temp = this.question.answers.filter((a) => a.userAnswer === true);
        if (temp.length === 0) {
          return 'EMPTY';
        }
        return this.question.answers.filter((a) => a.userAnswer === true);
      case 'list-multi-input':
        if (
          Array.isArray(this.question.userAnswers) &&
          this.question.userAnswers.length > 0
        ) {
          temp = this.question.userAnswers[0].answers.map((a) => {
            if (a.userAnswer === '') {
              return 'EMPTY';
            }
            if (!a.userAnswer) {
              return 'EMPTY';
            }
            return a.userAnswer;
          });
        }
        if (temp) {
          temp2 = temp.filter((t) => t !== 'EMPTY');
        }
        if (temp2 && temp2.length > 0) {
          return temp;
        }
        return 'EMPTY';
      case 'multi-input':
        if (Array.isArray(this.question.answers)) {
          temp = this.question.answers.map((a) => {
            if (a.userAnswer === '') {
              return 'EMPTY';
            }
            if (!a.userAnswer) {
              return 'EMPTY';
            }
            return a.userAnswer;
          });
        }
        temp2 = temp.filter((t) => t !== 'EMPTY');
        if (temp2.length > 0) {
          return temp;
        }
        return 'EMPTY';
      case 'list-faq':
        if (this.question.userAnswers) {
          if (Array.isArray(this.question.userAnswers)) {
            temp = this.question.userAnswers.map((a) => {
              if (a.userAnswer === '') {
                return 'EMPTY';
              }
              if (!a.userAnswer) {
                return 'EMPTY';
              }
              return a.userAnswer;
            });
          }
          temp2 = temp.filter((t) => t !== 'EMPTY');
          if (temp2.length > 0) {
            return temp;
          }
          return 'EMPTY';
        }
        if (Array.isArray(this.question.answers)) {
          temp = this.question.answers.map((a) => {
            if (a.userAnswer === '') {
              return 'EMPTY';
            }
            if (!a.userAnswer) {
              return 'EMPTY';
            }
            return a.userAnswer;
          });
        }
        temp2 = temp.filter((t) => t !== 'EMPTY');
        if (temp2.length > 0) {
          return temp;
        }
        return 'EMPTY';
    }
  }

  getAnswer() {
    switch (this.question.component) {
      default:
        return {
          study_id: MatgenGlobal.currentStudy.study.id,
          question_id: this.question.id,
          answer: JSON.stringify(this.question.answers),
          //answerObj: this.question.answers,
        };
      case 'list-multi-input':
      case 'upload-single':
        return {
          study_id: MatgenGlobal.currentStudy.study.id,
          question_id: this.question.id,
          answer: JSON.stringify(this.question.userAnswers),
          //answerObj: this.question.userAnswers,
        };
      case 'list-faq':
        return {
          study_id: MatgenGlobal.currentStudy.study.id,
          question_id: this.question.id,
          answer: this.question.userAnswers
            ? JSON.stringify(this.question.userAnswers)
            : JSON.stringify(this.question.answers),
          /*answerObj: this.question.userAnswers
            ? this.question.userAnswers
            : this.question.answers,*/
        };
    }
  }
}

export const saveStudyData = async (body, method = 'insert') => {
  const { headers } = await authHeaderOpts();
  //console.error('SAVE STUDY DATA:', method, body);
  switch (method) {
    default:
      break;
    case 'insert':
      return await MatgenGlobal.Amplify.API.post(
        'authenticated',
        '/user-content',
        {
          headers,
          body,
        }
      );
    case 'update':
      return await MatgenGlobal.Amplify.API.patch(
        'authenticated',
        '/user-content',
        {
          headers,
          body,
        }
      );
    case 'delete':
      return await MatgenGlobal.Amplify.API.del(
        'authenticated',
        `/user-content/${body}`,
        { headers }
      );
  }
};

export const prepareStudyData = async () => {
  const question_keys = Object.keys(MatgenGlobal.currentStudy.questions);

  const study_id = MatgenGlobal.getQueryParam('study_id');

  // const opts = await authHeaderOpts();
  const existing_data = await MatgenGlobal.Data.API.request(
    `/user-content/?user_folder_id=${study_id}`,
    'GET'
  );

  const inserts = [];
  //const updates = [];
  const deletes = [];

  for (let i = 0; i < question_keys.length; i++) {
    const q = new MatgenQuestion(
      MatgenGlobal.currentStudy.questions[question_keys[i]]
    );
    const existing = existing_data.find((d) => {
      return (
        d.user_folder_id === study_id && d.question_id === question_keys[i]
      );
    });

    if (existing) {
      if (q.getPlainTextAnswer() === 'EMPTY') {
        deletes.push(MatgenGlobal.currentStudy.questions[question_keys[i]]);
      } else {
        inserts.push(MatgenGlobal.currentStudy.questions[question_keys[i]]);
      }
    } else {
      if (q.getPlainTextAnswer() !== 'EMPTY') {
        inserts.push(MatgenGlobal.currentStudy.questions[question_keys[i]]);
      }
    }
  }
  return { inserts, deletes, existing_data, question_keys };
};

const removeDuplicatesByKey = (arr, key) => {
  const unique = {};
  const result = [];

  for (const item of arr) {
    if (!unique[item[key]]) {
      unique[item[key]] = true;
      result.push(item);
    }
  }

  return result;
};

export const studyDataSave = async (toValidate = true) => {
  const study_id = MatgenGlobal.getQueryParam('study_id');
  try {
    const db_response = await MatgenGlobal.Data.API.request(
      `/websites`,
      'PATCH',
      {
        user_folder_id: study_id,
        study_data_completed: '0',
      }
    );
    console.log(db_response);

    const valid = validateAndSetValues(toValidate);
    // const valid = true;
    if (valid) {
      MatgenGlobal.UI.loading('Saving study data...');

      const { inserts, deletes, existing_data } = await prepareStudyData();
      const promises = [];

      const answers =
        MatgenGlobal.currentStudy.questions[
          '99120986-a88a-46c1-ae87-2af83030740e'
        ].answers;
      const userAnswers = MatgenGlobal.currentStudy.questions[
        '99120986-a88a-46c1-ae87-2af83030740e'
      ].userAnswers
        ? MatgenGlobal.currentStudy.questions[
            '99120986-a88a-46c1-ae87-2af83030740e'
          ].userAnswers
        : [];

      const combinedArray = answers.concat(userAnswers);

      for (let i = 0; i < inserts.length; i++) {
        switch (MatgenGlobal.currentStudy.questions[inserts[i].id].component) {
          default:
            promises.push(
              saveStudyData(
                {
                  id: UUID(),
                  user_folder_id: study_id,
                  question_id: inserts[i].id,
                  answer: JSON.stringify(
                    MatgenGlobal.currentStudy.questions[inserts[i].id].answers
                  ),
                  material_id: 'website',
                },
                'insert'
              )
            );
            break;
          case 'richtext':
            promises.push(
              saveStudyData(
                {
                  id: UUID(),
                  user_folder_id: study_id,
                  question_id: inserts[i].id,
                  answer: JSON.stringify({
                    html: M4CGlobal.quill[inserts[i].id].root.innerHTML,
                    plain: M4CGlobal.quill[inserts[i].id].getText(),
                    data: M4CGlobal.quill[inserts[i].id].getContents(),
                  }),
                  material_id: 'website',
                },
                'insert'
              )
            );
            break;
          case 'list-multi-input':
          case 'upload-single':
            promises.push(
              saveStudyData(
                {
                  id: UUID(),
                  user_folder_id: study_id,
                  question_id: inserts[i].id,
                  answer: JSON.stringify(
                    MatgenGlobal.currentStudy.questions[inserts[i].id]
                      .userAnswers
                  ),
                  material_id: 'website',
                },
                'insert'
              )
            );
            break;
          case 'list-faq':
            promises.push(
              saveStudyData(
                {
                  id: UUID(),
                  user_folder_id: study_id,
                  question_id: '99120986-a88a-46c1-ae87-2af83030740e', //TODO
                  answer: JSON.stringify(
                    removeDuplicatesByKey(combinedArray, 'id')
                  ),
                  material_id: 'website',
                },
                'insert'
              )
            );
            break;
        }
      }

      /*for (let i = 0; i < updates.length; i++) {
        const answer = await MatgenGlobal.Data.API.request(
          `/user-content?user_folder_id=${study_id}&question_id=${
            updates[i].id
          }&material_id=${'website'}`
        );
        console.log(answer);

        switch (MatgenGlobal.currentStudy.questions[updates[i].id].component) {
          default:
            promises.push(
              saveStudyData(
                {
                  id: answer[0].id,
                  answer: JSON.stringify(
                    MatgenGlobal.currentStudy.questions[updates[i].id].answers
                  ),
                },
                'update'
              )
            );
            break;
          case 'richtext':
            promises.push(
              saveStudyData(
                {
                  id: answer[0].id,
                  answer: JSON.stringify({
                    html: M4CGlobal.quill[updates[i].id].root.innerHTML,
                    plain: M4CGlobal.quill[updates[i].id].getText(),
                    data: M4CGlobal.quill[updates[i].id].getContents(),
                  }),
                },
                'update'
              )
            );
            break;
          case 'list-multi-input':
          case 'upload-single':
            promises.push(
              saveStudyData(
                {
                  id: answer[0].id,
                  answer: JSON.stringify(
                    MatgenGlobal.currentStudy.questions[updates[i].id]
                      .userAnswers
                  ),
                },
                'update'
              )
            );
            break;
          case 'list-faq':
            promises.push(
              saveStudyData(
                {
                  id: answer[0].id,
                  answer: MatgenGlobal.currentStudy.questions[updates[i].id]
                    .userAnswers
                    ? JSON.stringify(
                        MatgenGlobal.currentStudy.questions[updates[i].id]
                          .userAnswers
                      )
                    : JSON.stringify(
                        MatgenGlobal.currentStudy.questions[updates[i].id]
                          .answers
                      ),
                },
                'update'
              )
            );
            break;
        }
      }*/

      for (let i = 0; i < deletes.length; i++) {
        const answer = await MatgenGlobal.Data.API.request(
          `/user-content?user_folder_id=${study_id}&question_id=${
            deletes[i].id
          }&material_id=${'website'}`
        );

        promises.push(saveStudyData(answer[0].id, 'delete'));
      }

      await Promise.all(promises);

      dataLayer.push({
        param1: existing_data && existing_data.length > 0 ? 'edit' : 'create',
        event: 'create_study_info',
      });
      const elapsedTime = formatTimer(Date.now() - MatgenGlobal.formTimer);

      dataLayer.push({
        param1: elapsedTime,
        event: 'studyinfo_time',
      });
      delete MatgenGlobal.formTimer;
      MatgenGlobal.UI.alertModal(
        null,
        MatgenGlobal.UI.bootstrapAlertHTML(
          'info',
          MatgenGlobal.infoIcon
            ? MatgenGlobal.infoIcon
            : 'fa-duotone fa-floppy-disk-circle-arrow-right',
          `<p>Your study data has been saved.<p>`
        )
      );
      MatgenGlobal.UI.stopLoading();
      return true;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
  return 'invalid';
};

// export const studyDataSaveSection = async (qids) => {
//   return qids;
// };

const getRequiredFields = () => {
  return [
    '945d40e7-2c59-4b9a-9dc8-d14d7dcdd648', //
    'b8cd8b13-d00f-4257-b1a9-41f4675e41bb', //
    '2626b183-1ca5-4fb6-a5f0-a81f214369a3', //
    'bf81ad48-b57a-47d1-b5a6-2a6ddf0a5e04', //
    '2f63c960-5e6a-4597-90b7-843073e4bea9', //
    'e6eaf634-b6c7-4782-82ae-b8e27fcab3fa', //
    'cc8ad9ff-0a19-489c-954f-818ac1bad220', //
    '900f8c49-b2c9-4d50-b1a5-c1535d734815', //
    '0093e24d-d016-424a-9e68-d5d93d65da03', //
    '498e0def-e28e-48ce-95c4-77d7e5553cf9', //
    '4254468c-9221-4f26-8a3c-50fd33aaf33c', //
    'dd4a6415-3688-4ff5-a65a-c502a27b1fa9',
  ];
};

export const validateForm = () => {
  const required = getRequiredFields();
  const answers = MatgenGlobal.currentStudy.answers;

  //select required)
  const requiredAs = answers.filter((a) => required.includes(a.question_id));

  // if any locations
  const locationQ = tryJSON(
    getMicrositeAnswer('c173b8de-a88f-4b94-8233-d33e4e686182')
  );

  if (locationQ) {
    for (let i = 0; i < locationQ.length; i++) {
      for (let j = 0; j < locationQ[i].answers.length; j++) {
        const A = JSON.parse(locationQ[i].answers[j].text);
        const UA = locationQ[i].answers[j].userAnswer;

        if (A.required && (!UA || UA === '')) {
          return false;
        }
      }
    }
  }

  // TODO: Common Questions

  return requiredAs.length == required.length;
};

export const validateAndSetValues = (validate = true) => {
  const questions = MatgenGlobal.currentStudy.questions;
  const invalid = [];
  if (validate) {
    if (!$('#microsite-form')[0].checkValidity()) {
      invalid.push({
        invalidElementsLength: $($('#microsite-form')[0]).find(':invalid')
          .length,
      });
    }
    $('.blue-box.richtext .head-and-sub-centered.required').each((i, el) => {
      let qid = $(el).next().attr('id');
      if (qid) {
        qid = qid.replace('form-container-', '');
        if (!M4CGlobal.quill[qid] || M4CGlobal.quill[qid].getText() === '\n') {
          $(el)
            .closest('.blue-box')
            .css('border', '1px solid #f00')
            .addClass('matgen-form-error');

          invalid.push({
            invalidquestionId: qid,
          });
        }
      }
    });
  }

  const question_keys = Object.keys(questions);
  for (let i = 0; i < question_keys.length; i++) {
    let answer, slider, yesNo;
    const required = getRequiredFields();

    switch (questions[question_keys[i]].component) {
      default:
        throw new Error(
          `Invalid component type: ${questions[question_keys[i]].component}`
        );
      case 'multi-input':
      case 'text':
      case 'textarea':
        setInputAnswers(questions[question_keys[i]]);
        break;
      case 'richtext':
        setRichTextAnswers(questions[question_keys[i]]);
        break;
      case 'list-multi-input':
        setListMultiInputAnswers(questions[question_keys[i]]);
        break;
      case 'select-multiple':
      case 'select-single':
      case 'card-select-single':
      case 'box-select-single':
      case 'list-select-single':
        answer = setSelectAnswers(questions[question_keys[i]]);
        if (
          validate &&
          answer.filter((o) => o.userAnswer === true).length === 0 &&
          required.includes(questions[question_keys[i]].id)
        ) {
          invalid.push({
            invalidquestionId: questions[question_keys[i]].id,
          });
        }
        break;
      case 'range':
        slider = document.getElementById(questions[question_keys[i]].id);
        questions[question_keys[i]].answers = [
          { userAnswer: slider.noUiSlider.get() },
        ];
        break;
      case 'upload-single':
        answer = $(`#upload-${questions[question_keys[i]].id}`).val();
        questions[question_keys[i]].userAnswers = questions[question_keys[i]]
          .userAnswers
          ? questions[question_keys[i]].userAnswers
          : [];
        questions[question_keys[i]].userAnswers[0] = {
          userAnswer: answer,
          yesNo,
        };
        /*if (validate && !answer) {
          invalid.push({
            invalidquestionId: questions[question_keys[i]].id,
          });
        }*/
        break;
      case 'list-faq':
        questions[question_keys[i]].answers = questions[
          question_keys[i]
        ].answers.map((a) => {
          if ($(`#${a.id}-answer`).attr('data-translated-question')) {
            questions[question_keys[i]].translatedQuestion = $(
              `#${a.id}-answer`
            ).attr('data-translated-question');
          }
          return {
            ...a,
            userAnswer: $(`#${a.id}-answer`).val(),
            translatedQuestion: $(`#${a.id}-answer`).attr(
              'data-translated-question'
            )
              ? $(`#${a.id}-answer`).attr('data-translated-question')
              : a.text,
          };
        });
        break;
      case 'img-select-single':
        if (
          validate &&
          questions[question_keys[i]].answers.filter(
            (a) => a.userAnswer === true
          ).length === 0
        ) {
          invalid.push({
            invalidquestionId: questions[question_keys[i]].id,
          });
        }
        break;
    }
  }

  if (invalid.length > 0) {
    const formEls = invalid.find((a) => a.invalidElementsLength);
    const questions = invalid.filter((a) => a.invalidquestionId);
    let invalidCount = 0;
    if (formEls) {
      invalidCount += formEls.invalidElementsLength;
    }
    if (questions.length > 0) {
      invalidCount += questions.length;
    }
    reportFormErrors(
      'microsite-form',
      questions.map((q) => q.invalidquestionId)
    );

    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $('.blue-box.matgen-form-error').first().offset().top,
      },
      250
    );

    window.setTimeout(() => {
      killModal('#microsite-preview-modal');
      MatgenGlobal.UI.alertModal(
        null,
        MatgenGlobal.UI.bootstrapAlertHTML(
          'danger',
          MatgenGlobal.dangerIcon
            ? MatgenGlobal.dangerIcon
            : 'fa-duotone fa-circle-exclamation',
          `<p>You have <b>${invalidCount}</b> validation errors on the tabs indicated with error icons. You must correct them before you can complete the website.<p>`
        )
      );
    }, 300);

    return false;
  }
  return true;
};

export const formatTimer = (ms) => {
  const milliseconds = ms % 1000;
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (60 * 1000)) % 60);
  const hours = Math.floor((ms / (3600 * 1000)) % 3600);
  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}:${milliseconds}`;
};

export const setInputAnswers = (question) => {
  const answers = question.answers;
  for (let j = 0; j < answers.length; j++) {
    let answer;
    if (isJsonAnswer(question.component)) {
      answer = $(`#${answers[j].id}`).val();
    } else {
      answer = answers[j].text;
    }
    question.answers[j].userAnswer = answer;
  }
};

export const setRichTextAnswers = (question) => {
  question.answers.userAnswer = {
    html: M4CGlobal.quill[question.id].root.innerHTML,
    plain: M4CGlobal.quill[question.id].getText(),
    data: M4CGlobal.quill[question.id].getContents(),
  };
};

export const setListMultiInputAnswers = (question) => {
  for (let i = 0; i < question.userAnswers.length; i++) {
    if (question.userAnswers[i].answers) {
      for (let j = 0; j < question.userAnswers[i].answers.length; j++) {
        const answer = $(`#${question.userAnswers[i].answers[j].id}`).val();
        question.userAnswers[i].answers[j].userAnswer = answer;
      }
    }
  }
};

export const isSelected = (a) => {
  return (
    $(`[data-answer-id="${a.id}"]`).hasClass('selected') ||
    $(`[data-answer-id="${a.id}"]`).attr('selected') ||
    $(`[data-answer-id="${a.id}"]`).hasClass('--selected') ||
    $(`[data-answer-id="${a.id}"]`).attr('--selected')
  );
};

export const setSelectAnswers = (question) => {
  const answers = question.answers;
  const selectedAnswers = [];
  for (let i = 0; i < answers.length; i++) {
    if (isSelected(answers[i])) {
      answers[i].userAnswer = true;
      selectedAnswers.push(answers[i]);
    } else {
      answers[i].userAnswer = false;
    }
  }
  return answers;
};

export const getMicrositeVars = (version = 1) => {
  let hero_answer = tryJSON(
    getMicrositeAnswer('498e0def-e28e-48ce-95c4-77d7e5553cf9')
  );
  if (!hero_answer) {
    hero_answer = [];
  }

  let logo_answer = tryJSON(
    getMicrositeAnswer('9a3e2212-632e-41ad-96cc-bc76c4231c70')
  );
  if (logo_answer) {
    logo_answer = logo_answer[0];
  }

  let name_answer = tryJSON(
    getMicrositeAnswer('b8cd8b13-d00f-4257-b1a9-41f4675e41bb')
  );
  if (name_answer) {
    name_answer = name_answer[0];
  }

  let welcome_answer = tryJSON(
    getMicrositeAnswer('cc8ad9ff-0a19-489c-954f-818ac1bad220')
  );
  if (welcome_answer && Array.isArray(welcome_answer)) {
    welcome_answer = welcome_answer[0];
  }
  welcome_answer = { userAnswer: welcome_answer.html };

  let why_answer = tryJSON(
    getMicrositeAnswer('6f78efb0-de27-44e9-a750-c9a557ae5f1c')
  );
  if (why_answer && Array.isArray(why_answer)) {
    why_answer = why_answer[0];
  }
  why_answer = { userAnswer: why_answer.html };

  let many_answer = tryJSON(
    getMicrositeAnswer('e6eaf634-b6c7-4782-82ae-b8e27fcab3fa')
  );
  if (many_answer && Array.isArray(many_answer)) {
    many_answer = many_answer[0];
  }
  many_answer = { userAnswer: many_answer.html };

  let benefits_answer = tryJSON(
    getMicrositeAnswer('900f8c49-b2c9-4d50-b1a5-c1535d734815')
  );
  if (benefits_answer && Array.isArray(benefits_answer)) {
    benefits_answer = benefits_answer[0];
  }
  benefits_answer = { userAnswer: benefits_answer.html };

  let happens_answer = tryJSON(
    getMicrositeAnswer('42d28066-b512-4aa8-ba56-860133909619')
  );
  if (happens_answer && Array.isArray(happens_answer)) {
    happens_answer = happens_answer[0];
  }
  happens_answer = { userAnswer: happens_answer.html };

  let who_answer = tryJSON(
    getMicrositeAnswer('484fd598-2c6c-4476-9818-0535909d323f')
  );
  if (who_answer && Array.isArray(who_answer)) {
    who_answer = who_answer[0];
  }
  who_answer = { userAnswer: who_answer.html };

  let goal_answer = tryJSON(
    getMicrositeAnswer('dd4a6415-3688-4ff5-a65a-c502a27b1fa9')
  );
  if (goal_answer && Array.isArray(goal_answer)) {
    goal_answer = goal_answer[0];
  }
  goal_answer = { userAnswer: goal_answer.html };

  let language_answer = tryJSON(
    getMicrositeAnswer('945d40e7-2c59-4b9a-9dc8-d14d7dcdd648')
  );
  if (!language_answer) {
    language_answer = 'English';
  }

  let phase_answer = tryJSON(
    getMicrositeAnswer('072d8175-47eb-41b7-a76b-a9415abb0949')
  );
  if (!phase_answer) {
    phase_answer = [];
  }

  let gender_answer = tryJSON(
    getMicrositeAnswer('bf81ad48-b57a-47d1-b5a6-2a6ddf0a5e04')
  );
  if (!gender_answer) {
    gender_answer = [];
  }

  let health_answer = tryJSON(
    getMicrositeAnswer('2f63c960-5e6a-4597-90b7-843073e4bea9')
  );
  if (!health_answer) {
    health_answer = [];
  }

  let investigators_answer = tryJSON(
    getMicrositeAnswer('b54ca956-43d4-46db-8451-8f66256e3432')
  );
  if (!investigators_answer) {
    investigators_answer = [];
  }

  let title_answer = tryJSON(
    getMicrositeAnswer('4254468c-9221-4f26-8a3c-50fd33aaf33c')
  );
  if (!title_answer) {
    title_answer = [];
  }
  if (title_answer && Array.isArray(title_answer)) {
    title_answer = title_answer.find((a) => a.userAnswer === true);
  }

  let contact_answer = tryJSON(
    getMicrositeAnswer('89b5c840-2638-459a-bf9f-c36c0c61b3a5')
  );
  if (!contact_answer) {
    contact_answer = [];
  }

  const locations = {};
  const location_question = tryJSON(
    getMicrositeAnswer('c173b8de-a88f-4b94-8233-d33e4e686182')
  );
  if (location_question) {
    for (let i = 0; i < location_question.length; i++) {
      const state = getVal(
        location_question[i].answers.find((l) => {
          const obj = tryJSON(l.text);
          return obj.type === 'standard-select-single';
        }),
        'userAnswer'
      );

      if (!locations[state]) {
        locations[state] = [];
      }
      locations[state].push(
        location_question[i].answers.map((a) => {
          a.userAnswer = sanitizeHTML(a.userAnswer);
          return a;
        })
      );
    }
  }

  const faq = tryJSON(
    getMicrositeAnswer('99120986-a88a-46c1-ae87-2af83030740e')
  );
  if (faq) {
    faq.sort((a, b) => a.sort_order - b.sort_order);
  }

  console.log('faq', faq);

  //MatgenGlobal.mstemp = microsite;

  const hero_image = sanitizeHTML(
    getVal(
      tryJSON(
        getVal(
          hero_answer.find((a) => {
            return a.userAnswer === true;
          }),
          'text'
        )
      ),
      'src'
    )
  );
  const hero_dir = sanitizeHTML(
    getVal(
      tryJSON(
        getVal(
          hero_answer.find((a) => {
            return a.userAnswer === true;
          }),
          'text'
        )
      ),
      'dir'
    )
  );
  const logo = sanitizeHTML(getVal(logo_answer, 'userAnswer'));

  return {
    updated: false,
    base_url:
      'https://m4c-matgen-microsites-new.s3.amazonaws.com/nia/template-001/',
    template_version: version,
    study_language:
      language_answer != 'English'
        ? sanitizeHTML(
            getVal(
              language_answer.find((a) => a.userAnswer === true),
              'text'
            )
          )
        : 'English',
    study_name: sanitizeHTML(getVal(name_answer, 'userAnswer'))
      ? sanitizeHTML(getVal(name_answer, 'userAnswer'))
      : '',
    welcome: sanitizeHTML(getVal(welcome_answer, 'userAnswer'))
      ? sanitizeHTML(getVal(welcome_answer, 'userAnswer'))
      : '',
    phase_text: sanitizeHTML(
      getVal(
        phase_answer.find((a) => a.userAnswer === true),
        'text'
      )
    ),
    phase_sub_text: sanitizeHTML(
      getVal(
        phase_answer.find((a) => a.userAnswer === true),
        'sub_text'
      )
    ),
    study_goal: sanitizeHTML(getVal(goal_answer, 'userAnswer')),
    min_age: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('2626b183-1ca5-4fb6-a5f0-a81f214369a3'))[0],
        'userAnswer'
      )[0]
    ),
    max_age: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('2626b183-1ca5-4fb6-a5f0-a81f214369a3'))[0],
        'userAnswer'
      )[1]
    ),
    gender: gender_answer
      .filter((a) => a.userAnswer)
      .map((a) => sanitizeHTML(a.text)),
    health_status: health_answer
      .filter((a) => a.userAnswer)
      .map((a) => sanitizeHTML(a.text)),
    conditions: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('4d88a934-f562-49d8-8d08-4c956a5ed012'))[0],
        'userAnswer'
      )
    ),
    requirements: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('484fd598-2c6c-4476-9818-0535909d323f'))[0],
        'userAnswer'
      )
    ),
    /*what_happens: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('42d28066-b512-4aa8-ba56-860133909619'))[0],
        'userAnswer'
      )
    ),*/
    /*how_long: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('6f78efb0-de27-44e9-a750-c9a557ae5f1c'))[0],
        'userAnswer'
      )
    ),*/
    how_long: sanitizeHTML(getVal(who_answer, 'userAnswer')),
    /*who_with: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('e6eaf634-b6c7-4782-82ae-b8e27fcab3fa'))[0],
        'userAnswer'
      )
    ),*/
    who_with: sanitizeHTML(getVal(many_answer, 'userAnswer')),
    benefits_risks: sanitizeHTML(getVal(benefits_answer, 'userAnswer')),
    what_happens: sanitizeHTML(getVal(happens_answer, 'userAnswer')),
    provided: sanitizeHTML(getVal(why_answer, 'userAnswer')),
    funding: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('0093e24d-d016-424a-9e68-d5d93d65da03'))[0],
        'userAnswer'
      )
    ),
    investigators: investigators_answer.map((a) => {
      return a.answers.map((ua) => {
        return {
          name: sanitizeHTML(JSON.parse(ua.text).label),
          value: sanitizeHTML(ua.userAnswer),
        };
      });
    }),
    primary_contact: contact_answer.map((a) => sanitizeHTML(a.userAnswer)),
    locations,
    faq,
    hero_image,
    hero_dir,
    logo,
    website_title: title_answer ? sanitizeHTML(title_answer.text) : '',
    website_subtitle: title_answer ? sanitizeHTML(title_answer.sub_text) : '',
  };
};

export const getVal = (find, type) => {
  return find ? find[type] : false;
};

export const getMicrositeAnswer = (id) => {
  return getVal(
    MatgenGlobal.currentStudy.answers.find((a) => a.question_id === id),
    'answer'
  );
};

export const sanitizeHTML = (str) => {
  if (str && typeof str === 'string') {
    try {
      return DOMPurify.sanitize(str);
    } catch (e) {
      console.error(e);
      return '';
    }
    //return str.replace(/[^\w. ]/gi, (c) => {
    //  return `&#${c.charCodeAt(0)};`;
    //});
  } else {
    return '';
  }
};

export const tryJSON = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return false;
  }
};
